<template>
    <div class="min-h-screen bg-[#F6F6F4]">
        <div class="sticky top-0 h-65 flex items-center px-15 bg-white z-20">
            <van-field
                class="py-10 text-14 leading-20 rounded-full bg-[#F4F4F4] mr-15"
                type="text"
                maxlength="50"
                input-align="center"
                v-model="formData.name"
                placeholder="患者姓名"
            />
            <van-field
                class="py-10 text-14 leading-20 rounded-full bg-[#F4F4F4] mr-15"
                type="number"
                maxlength="50"
                input-align="center"
                v-model="formData.phone"
                placeholder="患者号码"
            />
            <div class="__button _primary flex-shrink-0 w-80 h-40" @click="getListData({ init: true })">搜索</div>
        </div>

        <div class="mt-20 text-center text-28 text-[#898989]" v-if="medicalListData.noData">暂无数据</div>

        <van-list v-model="medicalListData.loading" :finished="medicalListData.over" finished-text="已经到底啦~" @load="getListData()" v-else>
            <div class="px-15">
                <div class="mt-12 rounded-10 overflow-hidden shadow px-20 bg-white bg-gradient-1" v-for="item in medicalListData.list" :key="item.id">
                    <div class="mt-15 flex items-center">
                        <div class="text-16 leading-22 font-bold">{{ item.name }}</div>
                        <div class="ml-6 w-16 h-16 rounded-3 bg-[#FF9933] text-center leading-16 text-10 text-white" v-if="item.seetypename">{{ item.seetypename }}</div>

                        <div class="ml-auto text-14 leading-20 text-[#9D9D9D]">{{ item.seetime }}</div>
                    </div>

                    <div class="mt-8 text-[#9D9D9D] text-14 leading-20">
                        <span>{{ item.sex }}</span>
                        <span class="ml-4">{{ item.age }}岁</span>
                        <span class="ml-4">{{ item.phone }}</span>
                    </div>

                    <div class="mt-10 h-1 bg-[#D8D8D8]"></div>

                    <div class="mt-10 flex text-14 leading-20" v-for="detail in detailDataList" :key="detail.key">
                        <div class="w-67 mr-10 text-[#9D9D9D] flex-shrink-0">{{ detail.title }}</div>
                        <div class="flex-1 text-right line-clamp-1 text-[#555555]">{{ item[detail.key] || "-" }}</div>
                    </div>

                    <div class="mx-auto my-20 w-120 __button _primary" @click="handleImportPrescription(item)">引入病历</div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
import { baseListData, updateListData } from "../../utils";
import { wxmedicalhistoryqrybyname } from "@/api/medical";
import { mapGetters } from "vuex";

export default {
    name: "medicalHistory",
    components: {},
    data() {
        return {
            formData: {
                name: "",
                phone: "",
            },
            medicalListData: { ...baseListData },

            detailDataList: [
                { key: "doctor", title: "开单医生" },
                { key: "complaint", title: "主诉" },
                { key: "medicalhistory", title: "病史" },
                { key: "pulsecondition", title: "脉象" },
                { key: "tongue", title: "舌象" },
                { key: "diagnose", title: "中医诊断" },
                { key: "westerndiagnostics", title: "西医诊断" },
            ],
        };
    },
    computed: {
        ...mapGetters(["username"]),
    },
    watch: {},
    methods: {
        async getListData({ init } = {}) {
            if (init) {
                this.medicalListData = { ...baseListData };
            }

            const data = this.medicalListData;
            if (data.over || data.noData) {
                return;
            }

            try {
                const { response_body: res } = await wxmedicalhistoryqrybyname(Object.assign({ username: this.username, qrypage: data.page }, this.formData));
                updateListData({ data, res });
            } catch (error) {
                updateListData({ type: "fail", data });
                console.error(error);
            }
        },

        /**
         * 导入病历
         * @param item
         */
        handleImportPrescription(item) {
            this.$store.commit("setImportPrescriptionData", item);
            this.$router.back();
        },
    },
    created() {
        this.$store.dispatch("hideOrShowNav", false);
        this.formData.name = this.$route.query.defaultName || "";
        this.formData.phone = this.$route.query.defaultPhone || "";
    },
};
</script>
<style lang="scss" scoped>
.bg-gradient-1 {
    background-image: linear-gradient(to bottom, rgba(207, 140, 87, 0.2), transparent 75px);
}
</style>
